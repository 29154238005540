import React, { useState } from "react";
import {
  Backdrop, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, makeStyles, MenuItem, 
  Select, TextField
} from "@material-ui/core";
import statuses from "../../../helpers/constants"

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  button: {
    marginTop: "12px"
  },
  formControl: {
    minWidth: "50%",
  },
  input: {
    display: "none",
  },
  selectedFile: {
    display: "flex",
    color: "black",
    fontSize: "1rem",
    marginTop: "12px",
    "& > div:first-child": {
      color: "gray",
      marginRight: "6px"
    }
  }
}));

function useFormFields(initialValues) {
    const [formFields, setFormFields] = useState(initialValues);
    const createChangeHandler = (key) => (e) => {
        const {value} = e.target;
        setFormFields((prev) => ({...prev, [key]: value}));
    };
    return { formFields, createChangeHandler };
  }

export default function EditPersonCandidate({ person, authSessionToken, handleClose, show }) {
  const classes = useStyles();
  const updatePersonUrl = `${process.env.REACT_APP_URL}/person`;
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [skillsetInput, setSkillsetInput] = useState([]);
  const [skillsetChips, setSkillsetChips] = useState(person.skillSets || []);
  const [skillsetError, setSkillsetError] = useState(false);
  const [tagInput, setTagInput] = useState([]);
  const [tagChips, setTagChips] = useState(person.skillSets || []);
  const [tagError, setTagError] = useState(false);
  const { formFields, createChangeHandler } = useFormFields({
    status: person.status || "",
    occupation: person.occupation || "",
    source: person.source || ""
  });

  const handleSkillsetInputChange = ({target: {value}}) => {
    setSkillsetInput(value);
  }
  const handleTagInputChange = ({target: {value}}) => {
    setTagInput(value);
  }

  const handleSkillsetKeyDown = (event) => {
    if(["Enter", "Tab", ","].includes(event.key)){
      event.preventDefault();
      const value = skillsetInput.trim();
      if(skillsetChips.find(chip => chip === value)){
        setSkillsetError(true)
      } else if(value){
          setSkillsetChips([...skillsetChips, value])
          setSkillsetInput("")
          setSkillsetError(false)
        }
    }
  }

  const handleTagKeyDown = (event) => {
    if(["Enter", "Tab", ","].includes(event.key)){
      event.preventDefault();
      const value = tagInput.trim();
      if(tagChips.find(chip => chip === value)){
        setTagError(true)
      } else if(value){
        setTagChips([...tagChips, value])
        setTagInput("")
        setTagError(false)
      }
    }
  }

  const handleSkillsetDelete = (chipToDelete) => () => {
    setSkillsetChips((skillsetChips) => skillsetChips.filter((chip) => chip !== chipToDelete))
  }


  const handleTagDelete = (chipToDelete) => () => {
    setTagChips((tagChips) => tagChips.filter((chip) => chip !== chipToDelete))
  }

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  }

  async function updatePerson() {
    await fetch(updatePersonUrl, {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
        Authorization: authSessionToken,
      },
      body: JSON.stringify({
        person_id: person.person_id,
        created_date: person.created_date,
        occupation: formFields.occupation,
        skillSets: skillsetChips,
        status: formFields.status,
        source: formFields.source,
        tags: tagChips,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        handleClose({ status: "success", msg: `Candidate was updated successfully!`})
        handleCloseBackdrop();
      })
      .catch((error) => {
        handleClose({ status: "error", msg: `Updating the person has failed. Error: ${error}`})
        handleCloseBackdrop();
      });
  }

  const handleSave = async (event) => {
    event.preventDefault();
    setOpenBackdrop(true)
    updatePerson();
  };

  return (
    <Dialog onClose={handleClose} open={show} maxWidth="md" disableEscapeKeyDown disableBackdropClick fullWidth>
      <form onSubmit={handleSave}>
        <DialogTitle id="form-dialog-title">Edit Candidate Information</DialogTitle>
        <DialogContent dividers>
          <FormControl className={classes.formControl}>
            <InputLabel id="status-label">Status</InputLabel>
            <Select
              labelId="status-label"
              id="status"
              value={formFields.status}
              onChange={createChangeHandler("status")}
            >
              {statuses.map((option) => (
                <MenuItem value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            label="Occupation"
            id="occupation"
            value={formFields.occupation}
            onChange={createChangeHandler("occupation")}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Source"
            id="source"
            value={formFields.source}
            onChange={createChangeHandler("source")}
            fullWidth
          />
          <TextField
            autoFocus
            margin="dense"
            label="Tags"
            id="tags"
            value={tagInput}
            onChange={handleTagInputChange}
            onKeyDown={handleTagKeyDown}
            fullWidth
            error={tagError}
            helperText={tagError ? "Tag entered already exists. Please enter a new one." : ""}
            InputProps={{
                startAdornment: tagChips.map((tag) => (
                  <Chip
                    key={tag}
                    label={tag}
                    onDelete={handleTagDelete(tag)}
                  />
                ))
              }}
          />
          <TextField
            autoFocus
            margin="dense"
            label="Skillsets"
            id="skillsets"
            value={skillsetInput}
            onChange={handleSkillsetInputChange}
            onKeyDown={handleSkillsetKeyDown}
            fullWidth
            error={skillsetError}
            helperText={skillsetError ? "Skillset entered already exists. Please enter a new one." : ""}
            InputProps={{
              startAdornment: skillsetChips.map((skillset) => (
                <Chip 
                  key={skillset}
                  label={skillset}
                  onDelete={handleSkillsetDelete(skillset)}
                />
              ))
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <Button type="submit" color="primary">
            Save
          </Button> 
        </DialogActions>
        <Backdrop className={classes.backdrop} open={openBackdrop} onClick={handleCloseBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </form>
    </Dialog>
  );
}

import React, { useState} from "react";
import axios from 'axios';
import { 
    Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, TextField, Typography
  } from "@material-ui/core";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff'
    },
    button: {
        marginTop: "12px"
    },
    input: {
        display: "none",
    },
    selectedFile: {
        display: "flex",
        color: "black",
        fontSize: "1rem",
        marginTop: "12px",
        "& > div:first-child": {
        color: "gray",
        marginRight: "6px"
        }
    }
}));

function useFormFields(initialValues) {
    const [formFields, setFormFields] = React.useState(initialValues);
    const createChangeHandler = (key) => (e) => {
      const {value} = e.target;
      const newValue = { value, touched: true}
      setFormFields((prev) => ({...prev, [key]: newValue }));
    };
    return { formFields, createChangeHandler };
}

export default function AddNewCandidate({ authSessionToken, handleClose, show}) {
    const classes = useStyles();
    const fileUrl = `${process.env.REACT_APP_URL}/uploadFile`;
    const personUrl = `${process.env.REACT_APP_URL}/person`;
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [fileUploaded, setFileUploaded] = useState(false);
    const { formFields, createChangeHandler } = useFormFields({
      first_name: { value : "", touched: false },
      last_name: { value : "", touched: false },
      email: { value : "", touched: false },
      phone_number: { value : "", touched: false },
      source: { value : "", touched: false },
      occupation: { value : "", touched: false }
    });
    const [selectedFile, setSelectedFile] = useState({
      fileContent: "",
      fileName: "",
    });

    const reset = () => {
        setFileUploaded(false);
        formFields.first_name = { value : "", touched: false };
        formFields.last_name = { value : "", touched: false };
        formFields.email = { value : "", touched: false };
        formFields.phone_number = { value : "", touched: false };
        formFields.source = { value : "", touched: false };
        formFields.occupation = { value : "", touched: false };
        setSelectedFile({fileContent: "",fileName: "",})
    }

    const handleCloseBackdrop = () => {
        setOpenBackdrop(false);
    }
    
    const handleFileUpload = ({ target }) => {
        const file = target.files[0];
        setSelectedFile(file)
        setFileUploaded(true)
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setOpenBackdrop(true)
        await uploadFile();
    };

    async function assignFileToPerson(resumeId) {
        const person = {
            first_name: formFields.first_name.value,
            last_name: formFields.last_name.value,
            email: formFields.email.value,
            phoneNumber: formFields.phone_number.value,
            source: formFields.source.value,
            occupation: formFields.occupation.value,
            resume_id: resumeId.resume_id,
            filename: resumeId.fileName,
            skillsets: null,
            personType: "Candidate"
        }
        await fetch(personUrl, {
          method: "POST", // or 'PUT'
          headers: {
            "Content-Type": "application/json",
            Authorization: authSessionToken,
          },
          body: JSON.stringify({...person}),
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            handleClose({ status: "success", msg: `Candidate was added successfully!`})
            handleCloseBackdrop();
            reset()
          })
          .catch((error) => {
            handleClose({ status: "error", msg: `Assigning the file to the person has failed. Error: ${error}`});
            handleCloseBackdrop();
          });
    }
    
    async function uploadFile() {
        const data = new FormData()
        data.set('file', selectedFile)
        data.set('folder', 'resumes')
        await axios.post(fileUrl, data, {
        }).then((response) => {
            return response;
        }).then(async(data) => {
                await assignFileToPerson(data.data);
        })
          .catch((error) => {
            handleClose({ status: "error", msg: `Uploading the file has failed. Error: ${error}`});
            handleCloseBackdrop();
          });
    }

    return (
      <Dialog open={show} onClose={handleClose} maxWidth="sm" disableEscapeKeyDown disableBackdropClick fullWidth aria-labelledby="form-dialog-title">
        <form onSubmit={handleSubmit}>
          <DialogTitle id="form-dialog-title">Add New Candidate</DialogTitle>
          <DialogContent dividers>
            <Typography variant="subtitle1" gutterBottom>Enter New Candidate Information:</Typography>
            <TextField
              autoFocus
              margin="dense"
              label="First Name"
              id="first_name"
              value={formFields.first_name.value}
              onChange={createChangeHandler("first_name")}
              onBlur={createChangeHandler("first_name")}
              fullWidth
              error={formFields.first_name.touched && formFields.first_name.value.trim() === ""}
              helperText={formFields.first_name.touched && formFields.first_name.value.trim() === "" ? "Required field." : ""}
              required
            />
            <TextField
              margin="dense"
              label="Last Name"
              id="last_name"
              value={formFields.last_name.value}
              onChange={createChangeHandler("last_name")}
              onBlur={createChangeHandler("last_name")}
              fullWidth
              error={formFields.last_name.touched && formFields.last_name.value.trim() === ""}
              helperText={formFields.last_name.touched && formFields.last_name.value.trim() === "" ? "Required field." : ""}
              required
            />
            <TextField
              margin="dense"
              id="email"
              label="Email Address"
              type="email"
              value={formFields.email.value}
              onChange={createChangeHandler("email")}
              fullWidth
            />
            <TextField
              margin="dense"
              label="Phone Number"
              id="phone_number"
              value={formFields.phone_number.value}
              onChange={createChangeHandler("phone_number")}
              fullWidth
            />
            <TextField
              margin="dense"
              label="Job Source"
              id="source"
              value={formFields.source.value}
              onChange={createChangeHandler("source")}
              fullWidth
            />
            <TextField
              margin="dense"
              label="Occupation"
              id="occupation"
              value={formFields.occupation.value}
              onChange={createChangeHandler("occupation")}
              fullWidth
            />
            <input
              accept="txt/*"
              className={classes.input}
              id="contained-button-file"
              type="file"
              onChange={handleFileUpload}
            />
            <label htmlFor="contained-button-file">
              <Button
                variant="contained"
                component="span"
                className={classes.button}
                startIcon={<CloudUploadIcon />}
              >
                Upload Resume
              </Button>
            </label>
            <div className={classes.selectedFile}>
              <div>Upload File:</div>
              <div>{selectedFile?.name}</div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
            <Button
              type="submit"
              disabled={
                formFields.first_name.value.trim() === "" ||
                formFields.last_name.value.trim() === ""
              }
              color="primary"
            >
              Submit
            </Button>
          </DialogActions>
          <Backdrop className={classes.backdrop} open={openBackdrop} onClick={handleCloseBackdrop}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </form>
      </Dialog>
    );
}

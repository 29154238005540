import React, { useState } from "react";
import {
  Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, TextField
} from "@material-ui/core";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  button: {
    marginTop: "12px"
  },
  input: {
    display: "none",
  },
  selectedFile: {
    display: "flex",
    color: "black",
    fontSize: "1rem",
    marginTop: "12px",
    "& > div:first-child": {
      color: "gray",
      marginRight: "6px"
    }
  }
}));

function useFormFields(initialValues) {
    const [formFields, setFormFields] = useState(initialValues);
    const createChangeHandler = (key) => (e) => {
        const {value} = e.target;
        const newValue = { value, touched: true}
        setFormFields((prev) => ({...prev, [key]: newValue}));
    };
    return { formFields, createChangeHandler };
  }

export default function EditPersonContact({ person, authSessionToken, handleClose, show }) {
  const classes = useStyles();
  const updatePersonUrl = `${process.env.REACT_APP_URL}/person`;
  const fileUrl = `${process.env.REACT_APP_URL}/uploadFile`;
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [newFileUploaded, setNewFileUploaded] = useState(false);
  const { formFields, createChangeHandler } = useFormFields({
    first_name: { value: person.first_name || "", touched: false },
    last_name: { value: person.last_name || "", touched: false },
    phone_number: { value: person.phone_number || "", touched: false },
    email: { value: person.email || "", touched: false },
    address: { value: person.address || "", touched: false },
    city: { value: person.city || "", touched: false },
    state: { value: person.state || "", touched: false },
    zipCode: { value: person.zipCode || "", touched: false },
  });
  const [selectedFile, setSelectedFile] = useState({
    fileContent: null,
    fileName: null,
  });

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  }

  const handleFileUpload = ({ target }) => {
    const reader = new FileReader();
    const file = target.files[0];

    reader.onload = function () {
      setSelectedFile({
        fileContent: reader.result.split(",")[1],
        fileName: file.name,
      });
      setNewFileUploaded(true);
    };
    reader.readAsDataURL(file);
  };

  async function updatePerson(resumeId) {
    await fetch(updatePersonUrl, {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
        Authorization: authSessionToken,
      },
      body: JSON.stringify({
        address: formFields.address.value,
        person_id: person.person_id,
        first_name: formFields.first_name.value,
        last_name: formFields.last_name.value,
        email: formFields.email.value,
        phone_number: formFields.phone_number.value,
        city: formFields.city.value,
        state: formFields.state.value,
        zipCode: formFields.zipCode.value,
        resume_id: resumeId || person.resume_id,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        handleClose({ status: "success", msg: `Candidate was updated successfully!`})
        handleCloseBackdrop();
      })
      .catch((error) => {
        handleClose({ status: "error", msg: `Assigning the file to the person has failed. Error: ${error}`})
        handleCloseBackdrop();
      });
  }

  async function uploadFileAndPerson() {
    await fetch(fileUrl, {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
        Authorization: authSessionToken,
      },
      body: JSON.stringify(selectedFile),
    })
      .then((response) => {
        return response.json();
      })
      .then(async(data) => {
        await updatePerson(data.resume_id);
      })
      .catch((error) => {
        handleClose({ status: "error", msg: `Uploading the file has failed. Error: ${error}`});
        handleCloseBackdrop();
      });
  }

  const handleSave = async (event) => {
    event.preventDefault();
    setOpenBackdrop(true)
    if(newFileUploaded){
      uploadFileAndPerson();
    } else {
      updatePerson();
    }
  };

  return (
    <Dialog onClose={handleClose} open={show} maxWidth="md" disableEscapeKeyDown disableBackdropClick fullWidth>
      <form onSubmit={handleSave}>
        <DialogTitle id="form-dialog-title">Edit Contact Information</DialogTitle>
        <DialogContent dividers>
          <TextField
            autoFocus
            margin="dense"
            label="First Name"
            id="first_name"
            value={formFields.first_name.value}
            onChange={createChangeHandler("first_name")}
            onBlur={createChangeHandler("first_name")}
            fullWidth
            error={formFields.first_name.touched && formFields.first_name.value.trim() === ""}
            helperText={formFields.first_name.touched && formFields.first_name.value.trim() === "" ? "Required field." : ""}
          />
          <TextField
            margin="dense"
            label="Last Name"
            id="last_name"
            value={formFields.last_name.value}
            onChange={createChangeHandler("last_name")}
            onBlur={createChangeHandler("last_name")}
            fullWidth
            error={formFields.last_name.touched && formFields.last_name.value.trim() === ""}
            helperText={formFields.last_name.touched && formFields.last_name.value.trim() === "" ? "Required field." : ""}
          />
          <TextField
            margin="dense"
            label="Phone Number"
            id="phone_number"
            value={formFields.phone_number.value}
            onChange={createChangeHandler("phone_number")}
            fullWidth
          />
          <TextField
            margin="dense"
            label="E-Mail"
            id="email"
            value={formFields.email.value}
            onChange={createChangeHandler("email")}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Address"
            id="address"
            value={formFields.address.value}
            onChange={createChangeHandler("address")}
            fullWidth
          />
          <TextField
            margin="dense"
            label="City"
            id="city"
            value={formFields.city.value}
            onChange={createChangeHandler("city")}
            fullWidth
          />
          <TextField
            margin="dense"
            label="State"
            id="state"
            value={formFields.state.value}
            onChange={createChangeHandler("state")}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Zip Code"
            id="zipCode"
            value={formFields.zipCode.value}
            onChange={createChangeHandler("zipCode")}
            fullWidth
          />
          <input
            accept="txt/*"
            className={classes.input}
            id="contained-button-file"
            type="file"
            onChange={handleFileUpload}
          />
          <label htmlFor="contained-button-file">
            <Button
              variant="contained"
              component="span"
              className={classes.button}
              startIcon={<CloudUploadIcon />}
            >
              Upload Resume
            </Button>
          </label>
          <div className={classes.selectedFile}>
            <div>Upload File:</div>
            <div>{selectedFile.fileName ? selectedFile.fileName : person.filename}</div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <Button type="submit" disabled={formFields.first_name.value.trim() === "" || formFields.last_name.value.trim() === ""} color="primary">
            Save
          </Button> 
        </DialogActions>
        <Backdrop className={classes.backdrop} open={openBackdrop} onClick={handleCloseBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </form>
    </Dialog>
  );
}

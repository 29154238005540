import React, { createContext, useReducer, useContext } from "react";

export const AuthContext = createContext();

// Initial state
const initialState = {
  sessionToken: {
    token: "",
    expires_at: 0
  },
  loading: true
};

// Actions
export const SET_TOKEN = "SET_TOKEN";

// Reducer
export function authReducer(state, action) {
  switch (action.type) {
    case SET_TOKEN:
      return { 
        sessionToken: action.payload.sessionToken,
        loading: false
      };
    default:
      throw new Error()
  }
}

function AuthProvider(props) {
  const [auth, dispatch] = useReducer(authReducer, initialState);

  const authData = { auth, dispatch };

  return <AuthContext.Provider value={authData} {...props} />;
}

function useAuthContext() {
  return useContext(AuthContext);
}

export { AuthProvider, useAuthContext };

import React, { useState, useEffect } from "react";
import { Link as ReactLink, useParams } from "react-router-dom";
import dayjs from 'dayjs' 
import dayjsPluginUTC from 'dayjs-plugin-utc'
import {
  Button, Grid, IconButton, Link, makeStyles, Paper, Snackbar, Typography
} from "@material-ui/core";
import {
  Edit as EditIcon, Work as WorkIcon
} from "@material-ui/icons"
import Skeleton from "@material-ui/lab/Skeleton"
import MuiAlert from '@material-ui/lab/Alert';
import { useAuthContext } from "../../contexts/AuthContext";
import { FetchFile } from "../../components"
import {
  AddRequisition as AddRequisitionDialog, EditPersonCandidate as EditPersonCandidateDialog, EditPersonContact as EditPersonContactDialog
} from './components'
import statuses from "../../helpers/constants"

dayjs.extend(dayjsPluginUTC);

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  actions: {
    textAlign: "right",
    padding: "10px 10px 20px 10px"
  },
  mainHeading: {
    padding: "10px 10px 20px 10px"
  },
  mainName: {
    color: "#02698c",
    fontSize: "1.75rem",
    fontWeight: "bold",
  },
  mainTitle: {
    fontSize: "1.20rem",
  },
  sectionHeading: {
    fontSize: "1.20rem",
    fontWeight: "bold",
    padding: "10px"
  },
  sectionContainer: {
    width: "inherit"
  },
  row: {
    "&:not(:last-child)": {
      borderBottom: '1px solid #cecece',
    },
    padding: "10px"
  },
  label: {
    color: "gray",
    fontSize: "1rem",
    fontWeight: "bold",
    textAlign: "center"
  },
  value: {
    fontSize: "1rem",
    textAlign: "left"
  },
  resumeMissing: {
    color: "gray",
    fontStyle: "italic"
  },
  resumeButton: {
    textTransform: "none"
  },
  skeletonActions: {
    justifyContent: "flex-end"
  }
}));

function getStatus(key){
  const status = statuses.find(status => status.value === key)
  if(!status) {
    return "Invalid Key. Cannot display value."
  }
  return status.label;
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Row({ label, value }) {
  const classes = useStyles();

  if (Array.isArray(value)) {
    value = value.join(', ')
  }

  return (
    <>
      <Grid item xs={6}>
        <div className={classes.label}>{label}</div>
      </Grid>
      <Grid item xs={6}>
        <div className={classes.value}>{value}</div>
      </Grid>
    </>
  );
}

function RequisitionLink({ name, jobReqId }) {
  return (
    <ReactLink
      to={`/requisition/${jobReqId}`}
    >
      {name}
    </ReactLink>
  );
}

function Resume({ person }) {
  const classes = useStyles();
  return (
    (() => {
      if (person.resumet_id) {
        return (
          <FetchFile item={person} />
        );
      }
      return (
        <Typography className={classes.resumeMissing}>No Resume Uploaded</Typography>
      )
    })()
  );
}

export default function Person() {
  const { personId } = useParams();
  const classes = useStyles();
  const { auth } = useAuthContext();
  const personUrl = `${process.env.REACT_APP_URL}/person?person_id=${personId}`;
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [showEditContactDialog, setShowEditContactDialog] = useState(false);
  const [showEditCandidateDialog, setShowEditCandidateDialog] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [person, setPerson] = useState();

  const handleEditCandidateDialogToggle = (queryResponse) => {
    if (queryResponse.status) {
      setOpenSnackbar(true);
      setSnackbarSeverity(queryResponse.status)
      setSnackbarMessage(queryResponse.msg)
      if (queryResponse.status === "success") {
        fetchPersonData();
      }
    }
    setShowEditCandidateDialog(!showEditCandidateDialog);
  }

  const handleEditContactDialogToggle = (queryResponse) => {
    if (queryResponse.status) {
      setOpenSnackbar(true);
      setSnackbarSeverity(queryResponse.status)
      setSnackbarMessage(queryResponse.msg)
      if (queryResponse.status === "success") {
        fetchPersonData();
      }
    }
    setShowEditContactDialog(!showEditContactDialog);
  }

  const handleAddDialogToggle = (queryResponse) => {
    if (queryResponse.status) {
      setOpenSnackbar(true);
      setSnackbarSeverity(queryResponse.status)
      setSnackbarMessage(queryResponse.msg)
      if (queryResponse.status === "success") {
        fetchPersonData();
      }
    }
    setShowAddDialog(!showAddDialog);
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false)
  }

  async function fetchPersonData() {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: auth.sessionToken.token,
      },
    };
    await fetch(personUrl, options)
      .then((response) => response.json())
      .then((data) => {
        setPerson(data);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    if (!auth.loading) {
      fetchPersonData();
    }
  }, [auth.loading]);

  return (
    <>
      {
        person ? (
          <div className={classes.root}>
            <Grid container>
              <Grid container item xs={6}>
                <Grid item className={classes.mainHeading}>
                  <Typography className={classes.mainName}>
                    {`${person.first_name} ${person.last_name}`}
                  </Typography>
                  <Typography className={classes.mainTitle}>
                    {person.occupation}
                  </Typography>
                  <Typography>
                    <Link href={`mailto:${person.email}`}>
                      {person.email}
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={6} className={classes.actions}>
                <Button
                  variant="contained"
                  onClick={handleAddDialogToggle}
                  color="primary"
                  className={classes.actionButton}
                  startIcon={<WorkIcon />}
                >
                  Change Assigned Requisition
                </Button>
              </Grid>
            </Grid>
            <Grid container>
              <Grid container item xs={12}>
                <Grid item>
                  <div className={classes.sectionHeading}>Candidate Information</div>
                </Grid>
                <Grid item>
                  <IconButton onClick={handleEditCandidateDialogToggle} fontSize="small">
                    <EditIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Paper className={classes.sectionContainer}>
                  <Grid container>
                    <Grid container item xs={12} className={classes.row}>
                      <Row label="Created Date" value={new Date(parseInt(person.created_date)).toLocaleString()} />
                    </Grid>
                    <Grid container item xs={12} className={classes.row}>
                      <Row
                        label="Last Updated" 
                        value={person.last_updated ? new Date(parseInt(person.last_updated)).toLocaleString() : ""}
                      />
                    </Grid>
                    <Grid container item xs={12} className={classes.row}>
                      <Row label="Status" value={person.status ? getStatus(person.status) : ""} />
                    </Grid>
                    <Grid container item xs={12} className={classes.row}>
                      <Row label="Occupation" value={person.occupation} />
                    </Grid>
                    <Grid container item xs={12} className={classes.row}>
                      <Row label="Applied For" value={<RequisitionLink name={person.lcat} jobReqId={person.jobReqId} />} />
                    </Grid>
                    <Grid container item xs={12} className={classes.row}>
                      <Row label="Source" value={person.source} />
                    </Grid>
                    <Grid container item xs={12} className={classes.row}>
                      <Row label="Tags" value={person.tags} />
                    </Grid>
                    <Grid container item xs={12} className={classes.row}>
                      <Row label="Skillsets" value={person.skillSets} />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <Grid container>
              <Grid container item xs={12}>
                <Grid item>
                  <div className={classes.sectionHeading}>Contact Information</div>
                </Grid>
                <Grid item>
                  <IconButton onClick={handleEditContactDialogToggle} fontSize="small">
                    <EditIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Paper className={classes.sectionContainer}>
                  <Grid container>
                    <Grid container item xs={12} className={classes.row}>
                      <Row label="Full Name" value={`${person.first_name} ${person.last_name}`} />
                    </Grid>
                    <Grid container item xs={12} className={classes.row}>
                      <Row label="E-Mail" value={person.email} />
                    </Grid>
                    <Grid container item xs={12} className={classes.row}>
                      <Row label="Phone Number" value={person.phone_number} />
                    </Grid>
                    <Grid container item xs={12} className={classes.row}>
                      <Row label="Address" value={person.address} />
                    </Grid>
                    <Grid container item xs={12} className={classes.row}>
                      <Row label="City" value={person.city} />
                    </Grid>
                    <Grid container item xs={12} className={classes.row}>
                      <Row label="State" value={person.state} />
                    </Grid>
                    <Grid container item xs={12} className={classes.row}>
                      <Row label="Zip Code" value={person.zipCode} />
                    </Grid>
                    <Grid container item xs={12} className={classes.row}>
                      <Row label="Resume" value={<Resume person={person} />} />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <AddRequisitionDialog
              personId={person.person_id}
              existingReqId={person.job_req_id}
              authSessionToken={auth.sessionToken.token}
              handleClose={handleAddDialogToggle}
              show={showAddDialog}
            />
            <EditPersonCandidateDialog
              person={person}
              authSessionToken={auth.sessionToken.token}
              handleClose={handleEditCandidateDialogToggle}
              show={showEditCandidateDialog}
            />
            <EditPersonContactDialog
              person={person}
              authSessionToken={auth.sessionToken.token}
              handleClose={handleEditContactDialogToggle}
              show={showEditContactDialog}
            />
            <Snackbar open={openSnackbar} autoHideDuration={5000} onClose={handleCloseSnackbar}>
              <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
                {snackbarMessage}
              </Alert>
            </Snackbar>
          </div>
        ) : (
          <div className={classes.root}>
            <Grid container>
              <Grid container item xs={8}>
                <Skeleton height={70} width={250} />
              </Grid>
              <Grid container item xs={4} className={classes.skeletonActions}>
                <Skeleton height={70} width={200} />
              </Grid>
            </Grid>
            <Grid container>
              <Skeleton height={45} width={200} />
            </Grid>
            <Skeleton height={250} variant="rect" />
            <Grid container>
              <Skeleton height={45} width={200} />
            </Grid>
            <Skeleton height={350} variant="rect" />
          </div>
          )
      }
    </>
  );
}

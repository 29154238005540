import React, { useState } from "react";
import { 
  Dialog
} from "@material-ui/core";
import {
  EmployeeType, ExistingEmployee, NewCandidate
} from "./components"

export default function UploadResume(props) {
  const { authSessionToken, handleClose, show} = props;
  const [step, setStep] = useState(1);
  const [resumeType, setResumeType] = useState('');

  const handleNextStep = (value) => {
    if(value){
      setResumeType(value);
    }
    setStep(step + 1)
  }

  const handlePreviousStep = () => {
    setResumeType('')
    setStep(step - 1)
  }

  const handleResetStep = () => {
    setStep(1);
  }

  return (
    <Dialog open={show} onClose={handleClose} maxWidth="sm" disableEscapeKeyDown disableBackdropClick fullWidth aria-labelledby="form-dialog-title">
      {
        (() => {
          switch (step) {
            case 1:
              return (
                <EmployeeType handleClose={handleClose} handleNextStep={handleNextStep} />
              );
            case 2:
              if(resumeType === "employee") {
                return (
                  <ExistingEmployee authSessionToken={authSessionToken} handleClose={handleClose} handlePreviousStep={handlePreviousStep} />
                );
              }
                return (
                  <NewCandidate 
                    authSessionToken={authSessionToken}
                    handleClose={handleClose}
                    handlePreviousStep={handlePreviousStep}
                    handleResetStep={handleResetStep}
                  />
                );
            default:
              return (
                <div>Error loading the correct step in this dialog.</div>
              )
          }
        })()
      }
    </Dialog>
  );
}

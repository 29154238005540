import React, { useState, useEffect } from "react";
import {
  Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Radio, TableCell, Typography
} from "@material-ui/core";
import { useAuthContext } from "../../../contexts/AuthContext";
import { EnhancedTable } from "../../../components"

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

export default function AddRequisition({ personId, existingReqId, authSessionToken, handleClose, show }) {
  const { auth } = useAuthContext();
  const classes = useStyles();
  const requisitionsUrl = `${process.env.REACT_APP_URL}/jobReq`;
  const updatePersonUrl = `${process.env.REACT_APP_URL}/person`;
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [requisitions, setRequisitions] = useState();
  const [disableApply, setDisableApply] = useState(true);
  const [selectedRequisition, setSelectedRequisition ] = useState()
  const [selectedRequisitionId, setSelectedRequisitionId] = useState(existingReqId);
  const [selectedDisplayRequisition, setSelectedDisplayRequisition] = useState("No Requisition Selected");

  function findExistingRequisition(id){
    return requisitions.find((requisition) => requisition.id === id)
  }

  function formatRequisitionDisplay(requisition){
    return requisition.lcat.trim() === "" ? requisition.project: `${requisition.lcat} (${requisition.project})`
  }

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  }

  const handleRadioChange = (event, requisition) => {
    if(event.target.value){
      setDisableApply(false);
    }
    setSelectedDisplayRequisition(formatRequisitionDisplay(requisition))
    setSelectedRequisition(requisition)
    setSelectedRequisitionId(event.target.value);
  }

  const TableCellRadioSelect = (requisition) => (
    <TableCell>
      <Radio
        checked={selectedRequisitionId === requisition.id}
        color="default"
        onChange={(e) => handleRadioChange(e, requisition)}
        value={requisition.id}
      />
    </TableCell>
  )

  const requisitionColumns = [
    {
      id: "radio_select",
      label: "",
      customColumn: TableCellRadioSelect,
      disableSorting: true,
      disableFiltering: true
    },
    {
      id: "lcat",
      label: "Category",
    },
    { id: "project", label: "Project" },
    { id: "jitr", label: "JITR" },
    { id: "level", label: "Level" }
  ];

  async function fetchData() {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: authSessionToken,
      },
    };
    await fetch(requisitionsUrl, options)
      .then((response) => response.json())
      .then((data) => {
        setRequisitions(data);
      })
      .catch((err) => console.log('error: ', err));
  }

  useEffect(() => {
    if (!auth.loading) {
      fetchData();
    }
  }, [auth.loading]);

  useEffect(() => {
    if(requisitions && existingReqId){
      setSelectedDisplayRequisition(formatRequisitionDisplay(findExistingRequisition(existingReqId)))
    }
  }, [requisitions])

  async function updateReqWithApplicant() {
    await fetch(`${requisitionsUrl}/addCandidate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authSessionToken,
      },
      body: JSON.stringify({
        id: selectedRequisitionId,
        candidate: personId
      }),
    }).then((response) => {
          return response.json();
        })
  }

  async function updatePerson() {
    setOpenBackdrop(true)
    await fetch(updatePersonUrl, {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
        Authorization: authSessionToken,
      },
      body: JSON.stringify({
        person_id: personId,
        jobReqId: selectedRequisitionId,
        lcat: selectedRequisition.lcat
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        updateReqWithApplicant()
        handleClose({ status: "success", msg: `Requisition has been applied successfully!`})
        handleCloseBackdrop()
        setDisableApply(true)
      })
      .catch((error) => {
        handleClose({ status: "error", msg: `Applying a requisition to a candidate has failed. Error: ${error}`})
        handleCloseBackdrop()
      });
  };

  return (
    <Dialog onClose={handleClose} open={show} maxWidth="md" disableEscapeKeyDown disableBackdropClick fullWidth>
      <DialogTitle>Add Requisition to Person</DialogTitle>
      <DialogContent dividers>
        <Typography variant="subtitle1" gutterBottom>Requisition Selected: {selectedDisplayRequisition}</Typography>
        {requisitions?.length > 0 ? (
          <div>
            <EnhancedTable
              columns={requisitionColumns}
              items={requisitions}
              itemId="id"
              filterKey="lcat"
              orderByKey="lcat"
              rowsPerPage={5}
              rowsPerPageOptions={[5, 10]}
              search
              searchLabel="Search Requisitions"
            />
          </div>
       ) : (
         <div style={{ margin: "90px 100px", width: "100%" }}>Loading...</div>
      )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        <Button disabled={disableApply} onClick={updatePerson} color="primary">
          Apply Requisition
        </Button>
      </DialogActions>
      <Backdrop className={classes.backdrop} open={openBackdrop} onClick={handleCloseBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
}

import React from 'react'
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";
import {TableCell} from "@material-ui/core";


const useStyles = theme => ({
    card1: {
        height: '125px',
        backgroundColor: '#36B5C0',
        marginRight: '24px',
    },
    content: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 0',
        height: '125px',
        color: '#00698C',
        fontWeight: 'bold',
    },
    header1: {
        backgroundColor:'#2B989F',
        float: 'left',
        height: '125px',
        width: '63px',
        color: '#fff',
        fontSize: '32px',
        textAlign: 'center',
        padding: '0',
        // fontWeight: 'bold'
    },
    icon: {width: '36px', height: '36px'},
    cardTitle:{color: 'white', fontWeight: 'bold'},

})

export default function Requisition(props) {
    const {
        active,
        image,
        location,
        numberOfInstances,
        title,
        id,
        lastModified
    } = props
    const classes = useStyles();
    return (
      <Grid item xs={12} sm={6} md={6} lg={3} style={{marginBottom:'8px'}}>
        <Card style={classes.card1}>
          <CardHeader
            disableTypography
            title={numberOfInstances}
            subheader={active ? <Typography>Active</Typography> : <Typography>Closed</Typography>}
            style={classes.header1}
          />
          <CardContent style={classes.content}>
            <div style={{display: 'flex', alignItems: 'center', padding: '0 .4rem', height: '125px'}}>
              <img
                src={image}
                style={classes.icon}
              />
            </div>
            <Grid container direction='column'>
              <Grid item>
                <Link
                  style={{textDecoration:'none'}}
                  to={`/requisition/${id}`}
                >
                  <Typography style={classes.cardTitle}>
                    {title}
                  </Typography>
                </Link>
              </Grid>
              {/* <Grid item> */}
              {/*  <Typography variant='subtitle2'> */}
              {/*    Organization: */}
              {/*  </Typography> */}
              {/* </Grid> */}
              {/* <Grid item> */}
              {/*  <Typography variant='subtitle2'> */}
              {/*    Salary: */}
              {/*  </Typography> */}
              {/* </Grid> */}
              <Grid item>
                <Typography variant='subtitle2'>
                  Location: {location}
                </Typography>
                  <Typography variant='subtitle2'>
                      Last Modified: {lastModified.toLocaleString()}
                  </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    )
}

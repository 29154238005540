/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:fd0c7e2c-2317-481d-aca9-696f1c69b111",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_9y1wM4CHY",
    "aws_user_pools_web_client_id": "12ceo3eec44f5t37v0h79ft4d8",
    "oauth": {},
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;

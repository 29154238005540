import React, {useState} from "react";
import { Chip } from "@material-ui/core"
import Description from "@material-ui/icons/Description";
import download from 'downloadjs'
import { useAuthContext } from "../../contexts/AuthContext";

export default function FetchFile({ item }) {
  const openFileUrl = `${process.env.REACT_APP_URL}/uploadFile/resume?resume_id=`;
  const { auth } = useAuthContext();
  const fileId = item.resume_id || item.fileId
  async function getFile(file) {
    if (file) {
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: auth.sessionToken.token,
        },
      };
      fetch(openFileUrl + file, options)
        .then( async (response) => {
          const blob = await response.blob()
          download(blob, item.filename[0])
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  return (
    <>{item.filename ? (
      <Chip
        icon={<Description />}
        label={item.filename}
        clickable
        onClick={async () => {
          await getFile(fileId);
        }}
        variant="outlined"
      />
    ) : (
        ""
      )}
    </>
  )
}
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Radio, TableCell, Typography
} from "@material-ui/core";
import { useAuthContext } from "../../../contexts/AuthContext";
import { EnhancedTable } from "../../../components"

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

export default function AddCandidate({ reqId, lcat, authSessionToken, handleClose, show }) {
  const { auth } = useAuthContext();
  const classes = useStyles();
  const candidatesUrl = `${process.env.REACT_APP_URL}/person`;
  const addPersonToJobReq = `${process.env.REACT_APP_URL}/jobReq/addCandidate`;
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [candidates, setCandidates] = useState([]);
  const [disableApply, setDisableApply] = useState(true);
  const [selectedPersonId, setSelectedPersonId] = useState();
  const [selectedDisplayPerson, setSelectedDisplayPerson] = useState("No Candidated Selected");

  const reset = () => {
    setDisableApply(true)
    setSelectedPersonId("");
    setSelectedDisplayPerson("No Candidate Selected");
  }
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  }

  const handleRadioChange = (event, person) => {
    if (event.target.value) {
      setDisableApply(false);
    }
    setSelectedDisplayPerson(`${person.display_name}`)
    setSelectedPersonId(event.target.value);
  }

  const TableCellRadioSelect = (person) => (
    <TableCell>
      <Radio
        checked={selectedPersonId === person.person_id}
        color="default"
        onChange={(e) => handleRadioChange(e, person)}
        value={person.person_id}
      />
    </TableCell>
  )

  const TableCellDisplayName = (item) => (
    <TableCell key={item.cellKey}>
      <Link
        style={{
          fontSize: "14px",
          fontWeight: "bold",
          color: "#5F6D80",
        }}
        target="_blank"
        to={`/person/${item.person_id}`}
      >
        {item.display_name}
      </Link>
    </TableCell>
  );

  const candidateColumns = [
    {
      id: "radio_select",
      label: "",
      customColumn: TableCellRadioSelect,
      disableSorting: true,
      disableFiltering: true
    },
    {
      id: "display_name",
      label: "Candidate",
      customColumn: TableCellDisplayName,
    },
    { id: "occupation", label: "Occupation" },
    { id: "location", label: "Location" }
  ];

  function formatCandidates(candidates) {
    candidates.forEach((candidate) => {
      candidate.display_name =
        `${candidate.first_name} ${candidate.last_name}`;
      candidate.location =
        `${candidate.city ? candidate.city : "N/A"
        } , ${candidate.state ? candidate.city : "N/A"}`;
    });
    return candidates;
  }

  async function fetchData() {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: authSessionToken,
      },
    };
    await fetch(candidatesUrl, options)
      .then((response) => response.json())
      .then((data) => {
        setCandidates(formatCandidates(data));
      })
      .catch((err) => console.log('error: ', err));
  }

  useEffect(() => {
    if (!auth.loading) {
      fetchData();
    }
  }, [auth.loading]);

  const updatePersonAndReq = async () => {
    await updatePerson()
    await updateCandidate()
  }

  async function updateCandidate() {
    await fetch(candidatesUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authSessionToken,
      },
      body: JSON.stringify({
        person_id: selectedPersonId,
        jobReqId: reqId,
        lcat
      }),
    })
        .then((response) => {
          return response.json();
        })
  }
  async function updatePerson() {
    setOpenBackdrop(true)
    await fetch(addPersonToJobReq, {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
        Authorization: authSessionToken,
      },
      body: JSON.stringify({
        person_id: selectedPersonId,
        job_req_id: reqId
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        handleClose({ status: "success", msg: `Candidate has been applied successfully!` })
        handleCloseBackdrop()
        reset()
      })
      .catch((error) => {
        handleClose({ status: "error", msg: `Applying a candidate to the requisition has failed. Error: ${error}` })
        handleCloseBackdrop()
      });
  };

  return (
    <Dialog onClose={handleClose} open={show} maxWidth="md" disableEscapeKeyDown disableBackdropClick fullWidth>
      <DialogTitle>Add Person to Requisition</DialogTitle>
      <DialogContent dividers>
        <Typography variant="subtitle1" gutterBottom>Candidate Selected: {selectedDisplayPerson}</Typography>
        {candidates.length > 0 ? (
          <div>
            <EnhancedTable
              columns={candidateColumns}
              items={candidates}
              itemId="person_id"
              filterKey="display_name"
              orderByKey="display_name"
              rowsPerPage={5}
              rowsPerPageOptions={[5, 10]}
              search
              searchLabel="Search Candidates"
            />
          </div>
        ) : (
          <div style={{ margin: "90px 100px", width: "100%" }}>Loading...</div>
          )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        {/*TODO: Reenable the disable apply to wait for resume submission*/}
        <Button disabled={false} onClick={updatePersonAndReq} color="primary">
          Add Person
        </Button>
      </DialogActions>
      <Backdrop className={classes.backdrop} open={openBackdrop} onClick={handleCloseBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
}

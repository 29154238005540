import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import dayjs from 'dayjs' 
import dayjsPluginUTC from 'dayjs-plugin-utc'
import {
  Button, Grid, makeStyles, Snackbar, TableCell, Typography
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton"
import MuiAlert from '@material-ui/lab/Alert';
import { EnhancedTable, FetchFile } from "../../components";
import { useAuthContext } from "../../contexts/AuthContext";
import AddNewRequisitionDialog from './components/add-new-requisition';

dayjs.extend(dayjsPluginUTC);

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  actions: {
    textAlign: "right",
    padding: "10px 10px 20px 10px"
  },
  mainHeading: {
    color: "#02698c",
    fontSize: "1.75rem",
    fontWeight: "bold",
  },
  sectionContainer: {
    width: "inherit"
  },
  sectionContent: {
    padding: "10px"
  },
  skeletonActions: {
    justifyContent: "flex-end"
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function formatRequisitions(requisitions) {
  requisitions.forEach((requisition) => {
    requisition.last_up_unix = requisition.last_updated ? dayjs(requisition.last_updated).unix() : null;
    requisition.created_unix = requisition.created_date ? dayjs(requisition.created_date).unix() : null;
  });
  return requisitions;
}

export default function Requisitions() {
  const classes = useStyles();
  const { auth } = useAuthContext();
  const url = `${process.env.REACT_APP_URL}/jobReq`;
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [requisitions, setRequisitions] = useState();

  async function fetchData() {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: auth.sessionToken.token,
      },
    };
    await fetch(url, options)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setRequisitions(formatRequisitions(data));
      })
      .catch((err) => {
        console.log('err: ', err);
      });
  }

  useEffect(() => {
    if (!auth.loading) {
      fetchData();
    }
  }, [auth.loading]);

  const TableCellCreated = (item) => (
    <TableCell key={item.cellKey}>
      { item.created_date ? dayjs.utc(item.created_date).local().format('MM/DD/YYYY hh:mma') : ""} 
    </TableCell>
  );

  const TableCellLcat = (item) => (
    <TableCell key={item.cellKey}>
      <Link
        style={{
          fontSize: "14px",
          fontWeight: "bold",
          color: "#5F6D80",
        }}
        to={`/requisition/${item.id}`}
      >
        {item.lcat}
      </Link>
    </TableCell>
  );

  const TableCellAttachment = (item) => (
    <TableCell key={item.cellKey}>
      <FetchFile item={item} />
    </TableCell>
  );

  const TableCellLastUpdated = (item) => (
    <TableCell key={item.cellKey}>
      { item.last_updated ? dayjs.utc(item.last_updated).local().format('MM/DD/YYYY hh:mma') : ""} 
    </TableCell>
  );

  const reqColumns = [
    { id: "created_unix", label: "Created Date", minWidth: 170, customColumn: TableCellCreated, disableFiltering: true},
    { id: "location", label: "Location", minWidth: 170 },
    {
      id: "lcat",
      label: "Category",
      minWidth: 170,
      customColumn: TableCellLcat,
    },
    { id: "project", label: "Project", minWidth: 170 },
    {
      id: "attachment",
      label: "Attachment",
      minWidth: 170,
      customColumn: TableCellAttachment,
      disableSorting: true,
      disableFiltering: true,
    },
    { id: "level", label: "Level", minWidth: 170 },
    { id: "last_up_unix", label: "Last Updated", minWidth: 170, customColumn: TableCellLastUpdated, disableFiltering: true}
  ];

  const handleAddDialogToggle = (queryResponse) => {
    if (queryResponse.status) {
      setOpenSnackbar(true);
      setSnackbarSeverity(queryResponse.status)
      setSnackbarMessage(queryResponse.msg)
      if (queryResponse.status === "success") {
        fetchData();
      }
    }
    setShowAddDialog(!showAddDialog);
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false)
  }

  return (
    <>
      {requisitions ? (
        <div className={classes.root}>
          <Grid container>
            <Grid container item xs={6}>
              <Typography className={classes.mainHeading}>Requisitions ({requisitions.length})</Typography>
            </Grid>
            <Grid item xs={6} className={classes.actions}>
              <Button
                variant="contained"
                color="primary"
                className={classes.actionButton}
                onClick={handleAddDialogToggle}
              >
                Add New Requisition
              </Button>
            </Grid>
          </Grid>
          <Grid container>
            <Grid container item xs={12}>
              <div className={classes.sectionContainer}>
                {
                  requisitions?.length > 0 ? (
                    <EnhancedTable
                      columns={reqColumns}
                      items={requisitions}
                      itemId="id"
                      filterKey="location"
                      orderByKey="created_unix"
                      orderType="desc"
                      search
                      searchLabel="Search Requisitions"
                    />
                  ) :
                    <Typography className={classes.sectionContent}>No Requisitions</Typography>
                }
              </div>
            </Grid>
          </Grid>
          <AddNewRequisitionDialog
            authSessionToken={auth.sessionToken.token}
            handleClose={handleAddDialogToggle}
            show={showAddDialog}
          />
          <Snackbar open={openSnackbar} autoHideDuration={5000} onClose={handleCloseSnackbar}>
            <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </div>
      ) : (
        <div className={classes.root}>
          <Grid container>
            <Grid container item xs={8}>
              <Skeleton height={75} width={250} />
            </Grid>
            <Grid container item xs={4} className={classes.skeletonActions}>
              <Skeleton height={75} width={175} />
            </Grid>
          </Grid>
          <Skeleton height={700} variant="rect" />
        </div>
        )}
    </>
  );
}

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import dayjs from 'dayjs' 
import dayjsPluginUTC from 'dayjs-plugin-utc'
import {
  Button, Chip, Grid, makeStyles, Snackbar, TableCell, Typography
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton"
import MuiAlert from '@material-ui/lab/Alert';
import { EnhancedTable, FetchFile } from "../../components"
import { useAuthContext } from "../../contexts/AuthContext";
import AddNewCandidateDialog from './components/add-new-candidate';

dayjs.extend(dayjsPluginUTC);

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  actions: {
    textAlign: "right",
    padding: "10px 10px 20px 10px"
  },
  mainHeading: {
    color: "#02698c",
    fontSize: "1.75rem",
    fontWeight: "bold",
  },
  sectionContainer: {
    width: "inherit"
  },
  sectionContent: {
    padding: "10px"
  },
  skeletonActions: {
    justifyContent: "flex-end"
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function formatCandidates(candidates) {
  candidates.forEach((candidate) => {
    candidate.display_name =
      `${candidate.first_name} ${candidate.last_name}`;
    if(candidate.city && candidate.state){
      candidate.location = `${candidate.city}, ${candidate.state}`
    } else if(candidate.city || candidate.state){
      candidate.location = candidate.city || candidate.state;
    } else {
      candidate.location = "";
    }
    candidate.last_up_unix = candidate.last_updated ? dayjs(candidate.last_updated).unix() : null;
    candidate.created_unix = candidate.created_date ? dayjs(candidate.created_date).unix() : null;
  });
  return candidates;
}

export default function Candidates() {
  const classes = useStyles();
  const { auth } = useAuthContext();
  const url = `${process.env.REACT_APP_URL}/person`;
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [candidates, setCandidates] = useState();

  async function fetchData() {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: auth.sessionToken.token,
      },
    };
    await fetch(url, options)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
          setCandidates(formatCandidates(data));
      })
      .catch((err) => {
        console.log('err: ', err);
      });
  }

  useEffect(() => {
  }, [candidates])

  useEffect(() => {
    if (!auth.loading) {
      fetchData();
    }
  }, [auth.loading]);

  const TableCellCreated = (item) => {
    const date = new Date(parseInt(item.created_date))
    return (
      <TableCell key={item.cellKey}>
        { item.created_date ? date.toLocaleString('en-US') : ""}
      </TableCell>
    )
  };

  const TableCellChips = (item) => {
    console.log(item.tags)
    return (
      <TableCell>
        {
        item.tags?.map(chip => (
          <Chip
            key={chip}
            label={chip}
          />
    )
      )
      }
      </TableCell>
    )
  }
  const TableCellDisplayName = (item) => (
    <TableCell key={item.cellKey}>
      <Link
        style={{
          fontSize: "14px",
          fontWeight: "bold",
          color: "#5F6D80",
        }}
        to={`/person/${item.person_id}`}
      >
        {item.display_name}
      </Link>
    </TableCell>
  );

  const TableCellViewResumeId = (item) => (
    <TableCell key={item.cellKey}>
      <FetchFile item={item} />
    </TableCell>
  );

  const TableCellLastUpdated = (item) => (
    <TableCell key={item.cellKey}>
      { item.last_updated ? new Date(parseInt(item.last_updated)).toLocaleString("en-US") : ""}
    </TableCell>
  );

  const candidateColumns = [
    { id: "created_unix", label: "Created Date", minWidth: 170, customColumn: TableCellCreated, disableFiltering: true},
    {
      id: "display_name",
      label: "Candidate",
      minWidth: 170,
      customColumn: TableCellDisplayName,
    },
    { id: "occupation", label: "Occupation", minWidth: 170 },
    { id: "email", label: "Email", minWidth: 170 },
    { id: "location", label: "Location", minWidth: 170 },
    { id: "tags", label: "Tags", minWidth: 170, customColumn: TableCellChips, disableFiltering: true},
    {
      id: "resume_id",
      label: "Attachment",
      minWidth: 200,
      customColumn: TableCellViewResumeId,
      disableSorting: true,
      disableFiltering: true,
    },
    { id: "last_up_unix", label: "Last Updated", minWidth: 170, customColumn: TableCellLastUpdated, disableFiltering: true},
  ];

  const handleAddDialogToggle = (queryResponse) => {
    if (queryResponse.status) {
      setOpenSnackbar(true);
      setSnackbarSeverity(queryResponse.status)
      setSnackbarMessage(queryResponse.msg)
      if (queryResponse.status === "success") {
        fetchData();
      }
    }
    setShowAddDialog(!showAddDialog);
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false)
  }

  return (
    <>
      {candidates ? (
        <div className={classes.root}>
          <Grid container>
            <Grid container item xs={6}>
              <Typography className={classes.mainHeading}>Candidates ({candidates.length})</Typography>
            </Grid>
            <Grid item xs={6} className={classes.actions}>
              <Button
                variant="contained"
                color="primary"
                className={classes.actionButton}
                onClick={handleAddDialogToggle}
              >
                Add New Candidate
              </Button>
            </Grid>
          </Grid>
          <Grid container>
            <Grid container item xs={12}>
              <div className={classes.sectionContainer}>
                {
                  candidates?.length > 0 ? (
                    <EnhancedTable
                      columns={candidateColumns}
                      items={candidates}
                      itemId="person_id"
                      filterKey="display_name"
                      orderByKey="created_unix"
                      orderType="desc"
                      search
                      searchLabel="Search Candidates"
                    />
                  ) :
                    <Typography className={classes.sectionContent}>No Candidates</Typography>
                }
              </div>
            </Grid>
          </Grid>
          <AddNewCandidateDialog
            authSessionToken={auth.sessionToken.token}
            handleClose={handleAddDialogToggle}
            show={showAddDialog}
          />
          <Snackbar open={openSnackbar} autoHideDuration={5000} onClose={handleCloseSnackbar}>
            <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </div>
      ) : (
        <div className={classes.root}>
          <Grid container>
            <Grid container item xs={8}>
              <Skeleton height={75} width={250} />
            </Grid>
            <Grid container item xs={4} className={classes.skeletonActions}>
              <Skeleton height={75} width={175} />
            </Grid>
          </Grid>
          <Skeleton height={700} variant="rect" />
        </div>
        )}
    </>
  );
}

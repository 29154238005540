import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import dayjs from 'dayjs' 
import dayjsPluginUTC from 'dayjs-plugin-utc'
import {
  Button, Grid, IconButton, makeStyles, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography
} from "@material-ui/core"
import {
  Edit as EditIcon, Work as WorkIcon
} from "@material-ui/icons"
import Skeleton from "@material-ui/lab/Skeleton"
import MuiAlert from '@material-ui/lab/Alert';
import { useAuthContext } from "../../contexts/AuthContext";
import {
  AddCandidate as AddCandidateDialog, EditRequisition as EditRequisitionDialog
} from "./components";
import { EnhancedTable, FetchFile } from "../../components";

dayjs.extend(dayjsPluginUTC);

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  actions: {
    textAlign: "right",
    padding: "10px 10px 20px 10px"
  },
  mainHeading: {
    padding: "10px 10px 20px 10px"
  },
  mainEdit: {
    paddingTop: "5px"
  },
  mainName: {
    color: "#02698c",
    fontSize: "1.75rem",
    fontWeight: "bold",
  },
  sectionHeading: {
    fontSize: "1.20rem",
    fontWeight: "bold",
    padding: "10px"
  },
  sectionContainer: {
    width: "inherit"
  },
  sectionContent: {
    padding: "10px"
  },
  row: {
    "&:not(:last-child)": {
      borderBottom: '1px solid #cecece',
    },
    padding: "10px"
  },
  label: {
    color: "gray",
    fontSize: "1rem",
    fontWeight: "bold",
    textAlign: "center"
  },
  value: {
    fontSize: "1rem",
    textAlign: "left"
  },
  attachmentMissing: {
    color: "gray",
    fontStyle: "italic"
  },
  skeletonActions: {
    justifyContent: "flex-end"
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Row({ label, value }) {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={6}>
        <div className={classes.label}>{label}</div>
      </Grid>
      <Grid item xs={6}>
        <div className={classes.value}>{value}</div>
      </Grid>
    </>
  );
}

function Attachment({ requisition }) {
  const classes = useStyles();
  return (
    (() => {
      if (requisition.attachment) {
        return (
          <FetchFile item={requisition} />
        );
      }
      return (
        <Typography className={classes.attachmentMissing}>No Requisition Uploaded</Typography>
      )
    })()
  );
}
export default function Requisition() {
  const { reqId } = useParams();
  const classes = useStyles();
  const { auth } = useAuthContext();
  const requisitionUrl = `${process.env.REACT_APP_URL}/jobReq?req_id=${reqId}`;
  const peopleForReqUrl = `${process.env.REACT_APP_URL}/person`;
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [requisition, setRequisition] = useState();
  const [applicants, setApplicants] = useState([]);

  function formatCandidate(candidate) {
      candidate.display_name =
        `${candidate.first_name} ${candidate.last_name}`;
      candidate.location =
        `${candidate.city ? candidate.city : "N/A"
        } , ${candidate.state ? candidate.city : "N/A"}`;
    return candidate;
  }

  const TableCellDisplayName = (item) => (
    <TableCell key={item.cellKey}>
      <Link
        style={{
          fontSize: "14px",
          fontWeight: "bold",
          color: "#5F6D80",
        }}
        target="_blank"
        to={`/person/${item.person_id}`}
      >
        {item.display_name}
      </Link>
    </TableCell>
  );

  const TableCellViewResumeId = (item) => (
    <TableCell key={item.cellKey}>
      <FetchFile item={item} />
    </TableCell>
  );

  const applicantColumns = [
    {
      id: "display_name",
      label: "Candidate",
      minWidth: 170,
      customColumn: TableCellDisplayName,
    },
    { id: "occupation", label: "Occupation", minWidth: 170 },
    { id: "email", label: "Email", minWidth: 170 },
    { id: "location", label: "Location", minWidth: 170 },
    {
      id: "resume_id",
      label: "Attachment",
      minWidth: 200,
      customColumn: TableCellViewResumeId,
      disableSorting: true,
      disableFiltering: true,
    },
  ];

  const handleEditDialogToggle = (queryResponse) => {
    if (queryResponse.status) {
      setOpenSnackbar(true);
      setSnackbarSeverity(queryResponse.status)
      setSnackbarMessage(queryResponse.msg)
      if (queryResponse.status === "success") {
        fetchRequisitionData();
      }
    }
    setShowEditDialog(!showEditDialog);
  }

  const handleAddDialogToggle = (queryResponse) => {
    if (queryResponse.status) {
      setOpenSnackbar(true);
      setSnackbarSeverity(queryResponse.status)
      setSnackbarMessage(queryResponse.msg)
      if (queryResponse.status === "success") {
        // fetchApplicants();
      }
    }
    setShowAddDialog(!showAddDialog);
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false)
  }

  async function fetchRequisitionData() {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: auth.sessionToken.token,
      }
    };
    await fetch(requisitionUrl, options)
      .then((response) => response.json())
      .then((data) => {
        const req = data;
        setRequisition(req);
      })
      .catch((err) => console.log(err));
  }

  async function fetchApplicants(applicantIds) {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: auth.sessionToken.token,
      },
    };
    if(applicantIds){
      applicantIds.map(async (candidate) => {
        await fetch(`${peopleForReqUrl}?person_id=${candidate.person_id}`, options)
            .then((response) => response.json())
            .then((data) => {
              const currentCandidate = formatCandidate(data)
              setApplicants(prevState => [...prevState, currentCandidate])
            })
            .catch((err) => console.log(err));
      })
    }
  }

  useEffect(() => {
    if (!auth.loading) {
      fetchRequisitionData()
    }
  }, [auth.loading]);

  useEffect(() => {
    if (!auth.loading) {
      fetchApplicants(requisition?.candidates)
    }
  }, [requisition])

  return (
    <>
      {
        requisition ? (
          <div className={classes.root}>
            <Grid container>
              <Grid container xs={8}>
                <Grid item className={classes.mainHeading}>
                  <Typography className={classes.mainName}>
                    {requisition.lcat}
                  </Typography>
                </Grid>
                <Grid item className={classes.mainEdit}>
                  <IconButton onClick={handleEditDialogToggle} fontSize="small">
                    <EditIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid item xs={4} className={classes.actions}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.actionButton}
                  onClick={handleAddDialogToggle}
                  startIcon={<WorkIcon />}
                >
                  Add Candidate to Requisition
                </Button>
              </Grid>
            </Grid>
            <Grid container>
              <Grid container item xs={8}>
                <Grid item>
                  <div className={classes.sectionHeading}>Key Details</div>
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Paper className={classes.sectionContainer}>
                  <Grid container>
                    <Grid container item xs={12} className={classes.row}>
                      <Row label="Position" value={requisition.position} />
                    </Grid>
                    <Grid container item xs={12} className={classes.row}>
                      <Row label="Created Date" value={dayjs.utc(requisition.created_date).local().format('MM/DD/YYYY hh:mma')} />
                    </Grid>
                    <Grid container item xs={12} className={classes.row}>
                      <Row
                        label="Last Updated" 
                        value={requisition.last_updated ? dayjs.utc(requisition.last_updated).local().format('MM/DD/YYYY hh:mma') : ""}
                      />
                    </Grid>
                    <Grid container item xs={12} className={classes.row}>
                      <Row label="Location" value={requisition.location} />
                    </Grid>
                    <Grid container item xs={12} className={classes.row}>
                      <Row label="Project" value={requisition.project} />
                    </Grid>
                    <Grid container item xs={12} className={classes.row}>
                      <Row label="Client" value={requisition.client} />
                    </Grid>
                    <Grid container item xs={12} className={classes.row}>
                      <Row label="Level" value={requisition.level} />
                    </Grid>
                    <Grid container item xs={12} className={classes.row}>
                      <Row label="Clearance" value={requisition.clearance} />
                    </Grid>
                    <Grid container item xs={12} className={classes.row}>
                      <Row label="Attachment" value={<Attachment requisition={requisition} />} />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <Grid container>
              <Grid container item xs={12}>
                <Grid item>
                  <div className={classes.sectionHeading}>Requisition Information</div>
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Paper className={classes.sectionContainer}>
                  <Grid container>
                    <Grid container item xs={12} className={classes.row}>
                      <Row label="Description" value={requisition.description} />
                    </Grid>
                    <Grid container item xs={12} className={classes.row}>
                      <Row label="Mandatory Requirements" value={requisition.mandatory} />
                    </Grid>
                    <Grid container item xs={12} className={classes.row}>
                      <Row label="Optional Requirements" value={requisition.optional} />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <Grid container>
              <Grid container item xs={12}>
                <Grid item>
                  <div className={classes.sectionHeading}>Applicants</div>
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <div className={classes.sectionContainer}>
                  {
                    applicants?.length > 0 && (
                    <>
                      <EnhancedTable
                        columns={applicantColumns}
                        items={applicants}
                        itemId="person_id"
                        filterKey="display_name"
                        orderByKey="display_name"
                        search={false}
                      />
                    </>
                    )
                  }
                </div>
              </Grid>
            </Grid>
            <AddCandidateDialog reqId={reqId} lcat={requisition.lcat} authSessionToken={auth.sessionToken.token} handleClose={handleAddDialogToggle} show={showAddDialog} />
            <EditRequisitionDialog
              requisition={requisition}
              authSessionToken={auth.sessionToken.token}
              handleClose={handleEditDialogToggle}
              show={showEditDialog}
            />
            <Snackbar open={openSnackbar} autoHideDuration={5000} onClose={handleCloseSnackbar}>
              <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
                {snackbarMessage}
              </Alert>
            </Snackbar>
          </div>
        )
          : (
            <div className={classes.root}>
              <Grid container>
                <Grid container item xs={8}>
                  <Skeleton height={70} width={250} />
                </Grid>
                <Grid container item xs={4} className={classes.skeletonActions}>
                  <Skeleton height={70} width={200} />
                </Grid>
              </Grid>
              <Grid container>
                <Skeleton height={45} width={200} />
              </Grid>
              <Skeleton height={350} variant="rect" />
              <Grid container>
                <Skeleton height={45} width={200} />
              </Grid>
              <Skeleton height={125} variant="rect" />
              <Grid container>
                <Skeleton height={45} width={200} />
              </Grid>
              <Skeleton height={300} variant="rect" />
            </div>
          )
      }
    </>
  );
}
import React, {useEffect, useState} from "react";
import {
  Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, TextField
} from "@material-ui/core";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import axios from "axios";
import uploadFile from "../../../helpers/uploadFileHelper";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  button: {
    marginTop: "12px"
  },
  input: {
    display: "none",
  },
  selectedFile: {
    display: "flex",
    color: "black",
    fontSize: "1rem",
    marginTop: "12px",
    "& > div:first-child": {
      color: "gray",
      marginRight: "6px"
    }
  }
}));

function useFormFields(initialValues) {
    const [formFields, setFormFields] = useState(initialValues);
    const createChangeHandler = (key) => (e) => {
        const {value} = e.target;
        const newValue = { value, touched: true}
        setFormFields((prev) => ({...prev, [key]: newValue}));
    };
    return { formFields, createChangeHandler };
  }

export default function EditRequisition({ requisition, authSessionToken, handleClose, show }) {
  const classes = useStyles();
  const updateReqUrl = `${process.env.REACT_APP_URL}/jobReq`;
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);
  const { formFields, createChangeHandler } = useFormFields({
    lcat: { value: requisition.lcat || "", touched: false },
    position: { value: requisition.position || "", touched: false },
    jitr: { value: requisition.jitr || "", touched: false },
    location: { value: requisition.location || "", touched: false },
    project: { value: requisition.project || "", touched: false },
    client: { value: requisition.client || "", touched: false },
    level: { value: requisition.level || "", touched: false },
    clearance: { value: requisition.clearance || "", touched: false },
    description: { value: requisition.description || "", touched: false },
    mandatory: { value: requisition.mandatory || "", touched: false },
    optional: { value: requisition.optional || "", touched: false }
  });
  const [fileId, setFileId] = useState()
  const [selectedFile, setSelectedFile] = useState({
    fileContent: "",
    fileName: "",
  });

  const getRowCount = (stringLength) => {
    if(stringLength > 400){
        return 5;
    }
    if(stringLength > 300 && stringLength <= 400){
        return 4;
    }
    if(stringLength > 200 && stringLength <= 300){
        return 3;
    }
    if(stringLength > 100 && stringLength <= 200){
        return 2
    }
    return 1;
  }

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  }

  const handleFileUpload = ({ target }) => {
    const file = target.files[0];
    setSelectedFile(file)
    setFileUploaded(true)
  };

  async function updateRequisition() {
    const body = {
      id: requisition.id,
      fileId: await uploadFile(selectedFile, 'resumes'),
      fileName: selectedFile.name,
      lcat: formFields.lcat.value,
      location: formFields.location.value,
      project: formFields.project.value,
      client: formFields.client.value,
      level: formFields.level.value,
      clearance: formFields.clearance.value,
      description: formFields.description.value,
      mandatory: formFields.mandatory.value,
      optional: formFields.optional.value,
    }
    if(formFields.position.value){
      body.position = formFields.position.value
    }
    await fetch(updateReqUrl, {
      method: "POST", // or 'PUT'
      headers: {
          "Content-Type": "application/json",
          Authorization: authSessionToken
      },
      body: JSON.stringify({...body}),
    })
    .then((response) => {
        return response.json();
    })
    .then((data) => {
        handleClose({ status: "success", msg: `Requisition has been updated successfully!`})
        handleCloseBackdrop()
    })
    .catch((error) => {
        handleClose({ status: "error", msg: `Editing the requisition has failed. Error: ${error}`})
        handleCloseBackdrop()
    });
  }

  const handleSave = async (event) => {
    event.preventDefault();
    setOpenBackdrop(true)
    setFileId(await uploadFile(selectedFile, 'requisitions'))
    await updateRequisition()
  };

  return (
    <Dialog onClose={handleClose} open={show} maxWidth="md" disableEscapeKeyDown disableBackdropClick fullWidth>
      <form onSubmit={handleSave}>
        <DialogTitle id="form-dialog-title">Edit Requisition</DialogTitle>
        <DialogContent dividers>
          <TextField
            autoFocus
            margin="dense"
            label="Position"
            id="position"
            value={formFields.position.value}
            onChange={createChangeHandler("position")}
            type="number"
            fullWidth
            helperText={`Position must be an integer. ${(formFields.position.value === "" ? "Empty value will not be saved." : "")}`}
          />
          <TextField
            margin="dense"
            label="JITR"
            id="jitr"
            disabled
            value={formFields.jitr.value}
            type="number"
            fullWidth
          />
          <TextField
            margin="dense"
            label="Category"
            id="lcat"
            value={formFields.lcat.value}
            onChange={createChangeHandler("lcat")}
            onBlur={createChangeHandler("lcat")}
            fullWidth
            error={formFields.lcat.touched && formFields.lcat.value.trim() === ""}
            helperText={formFields.lcat.touched && formFields.lcat.value.trim() === "" ? "Required field." : ""}
            required
          />
          <TextField
            margin="dense"
            label="Location"
            id="location"
            value={formFields.location.value}
            onChange={createChangeHandler("location")}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Project"
            id="project"
            value={formFields.project.value}
            onChange={createChangeHandler("project")}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Client"
            id="client"
            value={formFields.client.value}
            onChange={createChangeHandler("client")}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Level"
            id="level"
            value={formFields.level.value}
            onChange={createChangeHandler("level")}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Clearance"
            id="clearance"
            value={formFields.clearance.value}
            onChange={createChangeHandler("clearance")}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Description"
            id="description"
            value={formFields.description.value}
            onChange={createChangeHandler("description")}
            fullWidth
            multiline
            rows={getRowCount(formFields.description.value?.length)}
          />
          <TextField
            margin="dense"
            label="Mandatory Requirements"
            id="mandatory"
            value={formFields.mandatory.value}
            onChange={createChangeHandler("mandatory")}
            fullWidth
            multiline
            rows={getRowCount(formFields.mandatory.value?.length)}
          />
          <TextField
            margin="dense"
            label="Optional Requirements"
            id="optional"
            value={formFields.optional.value}
            onChange={createChangeHandler("optional")}
            fullWidth
            multiline
            rows={getRowCount(formFields.optional.value?.length)}
          />
          <input
            accept="txt/*"
            className={classes.input}
            id="contained-button-file"
            type="file"
            onChange={handleFileUpload}
          />
          <label htmlFor="contained-button-file">
            <Button
              variant="contained"
              component="span"
              className={classes.button}
              startIcon={<CloudUploadIcon />}
            >
              Upload Job Requisition
            </Button>
          </label>
          <div className={classes.selectedFile}>
            <div>Upload File:</div>
            <div>{selectedFile?.name}</div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <Button type="submit" disabled={formFields.lcat.value.trim() === ""} color="primary">
            Save
          </Button> 
        </DialogActions>
        <Backdrop className={classes.backdrop} open={openBackdrop} onClick={handleCloseBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </form>
    </Dialog>
  );
}

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card, InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField
} from "@material-ui/core";
import { Search } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  headCell: {
    backgroundColor: "#e8e8e8"
  },
  tableContainer: {
    maxHeight: 1000,
    width: "100%",
  },
  searchContainer: {
    margin: theme.spacing(2)
  },
  searchInput: {
    width: "50%",
    marginRight: "1em",
  },
  sortLabel: {
    "&:hover": {
      color: 'black',
    },
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  TableCell: {
    height: "65px"
  },
}));

EnhancedTableHead.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function EnhancedTableHead(props) {
  const { columns, classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map((headCell) => (
          <TableCell
            key={headCell.id}
            className={classes.headCell}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.disableSorting ? (
              headCell.label
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                className={classes.sortLabel}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const tableCells = (rowId, item, columns, classes) =>
  columns.map((column) => {
    item.cellKey = `${rowId}-${column.id}`;
    if (column.customColumn) {
      return column.customColumn(item);
    }
    return <TableCell className={classes.TableCell} key={item.cellKey}>{item[column.id]}</TableCell>;

  });

EnhancedTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  itemId: PropTypes.string.isRequired,
  filterKey: PropTypes.string.isRequired,
  orderType: PropTypes.oneOf(["asc", "desc"]),
  orderByKey: PropTypes.string.isRequired,
  page: PropTypes.number,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  rowsPerPage: PropTypes.number,
  search: PropTypes.bool,
  searchLabel: PropTypes.string,
};

EnhancedTable.defaultProps = {
  orderType: "asc",
  page: 0,
  rowsPerPage: 10,
  rowsPerPageOptions: [10, 25, 50, 100],
  search: false,
  searchLabel: "Search",
};

export default function EnhancedTable({
  items, columns, itemId, filterKey, orderType, orderByKey, page, rowsPerPage, rowsPerPageOptions, search, searchLabel
}) {
  const classes = useStyles();
  const [filterFn, setFilterFn] = React.useState({
    fn: (items) => {
      return items;
    },
  });
  console.log("ITEM'S FROM THE REQ", items)
  const [order, setOrder] = useState(orderType);
  const [orderBy, setOrderBy] = useState(orderByKey);
  const [pageValue, setPageValue] = useState(page);
  const [rowsPerPageValue, setRowsPerPageValue] = useState(rowsPerPage);
  const [filter, setFilter] = useState(filterKey);
  const [searchText, setSearchText] = useState("");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPageValue(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPageValue(event.target.value);
    setPageValue(0);
  };

  const handleChangeSearchText = (event) => {
    const { value } = event.target;
    setSearchText(value);
    if(pageValue !== 0){
      setPageValue(0);
    }
  };

  useEffect(() => {
    setFilterFn({
      fn: (items) => {
        if (searchText === "") return items;
          return items.filter((x) => {
            if (x[filter] !== null && x[filter] !== undefined) {
              if (typeof x[filter] === "number") {
                return x[filter]
                  .toString()
                  .includes(searchText.toLowerCase());
              } 
                return x[filter]
                  .toLowerCase()
                  .includes(searchText.toLowerCase());
              
            }
            return false;
          });
      },
    });
  }, [searchText])

  const handleChangeFilter = (event) => {
    setSearchText("");
    setFilter(event.target.value);
    setFilterFn({
      fn: (items) => {
        return items;
      },
    });
  };

  return (
    <Card style={{ backgroundColor: "transparent" }}>
      {search ? (
        <div className={classes.searchContainer}>
          <TextField
            variant="outlined"
            label={searchLabel}
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            value={searchText}
            onChange={handleChangeSearchText}
          />
          <TextField
            id="outlined-select-filter-native"
            select
            label="Filter"
            value={filter}
            onChange={handleChangeFilter}
            SelectProps={{
            native: true,
          }}
            variant="outlined"
          >
            {
              columns.filter(option => !option.disableFiltering).map(option => {
                  return (
                    <option key={option.id} value={option.id}>
                      {option.label}
                    </option>
                  )
              })
            }
          </TextField>
        </div>
      ) : (
        ""
      )}
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader>
          <EnhancedTableHead
            classes={classes}
            columns={columns}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {stableSort(filterFn.fn(items), getComparator(order, orderBy))
              .slice(pageValue * rowsPerPageValue, pageValue * rowsPerPageValue + rowsPerPageValue)
              .map((item) => {
                return (
                  <TableRow key={item[itemId]}>
                    {tableCells(item[itemId], item, columns, classes)}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={filterFn.fn(items).length}
        rowsPerPage={rowsPerPageValue}
        page={pageValue}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Card>
  );
}

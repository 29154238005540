const statuses = [
  {
    value: 'APPLIED',
    label: 'Applied/Source',
  },
  {
    value: 'PHONE',
    label: 'Phone Screen',
  },
  {
    value: 'QUALIFIED',
    label: 'Qualified',
  },
  {
    value: 'DETAILED_INT',
    label: 'Detailed Interview',
  },
  {
    value: 'CLIENT_INT',
    label: 'Client Interview',
  },
  {
    value: 'OFFER_LETTER',
    label: 'Offer Letter',
  },
  {
    value: 'HIRED',
    label: 'Hired',
  },
  {
    value: 'REJECTED',
    label: 'Rejected',
  },
];
export default statuses;
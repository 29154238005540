import axios from "axios";

/**
 *
 * @param selectedFile This is the selected file Blob
 * @param folder Sets which folder to send in the S3 buckets current options are resumes || requisitions
 * @returns {Promise<void>}
 */
async function uploadFile(selectedFile, folder) {
    const formData = new FormData()
    const fileUrl = `${process.env.REACT_APP_URL}/uploadFile`;
    let fileId
    formData.set('file', selectedFile)
    formData.set('folder', 'resumes')
    await axios.post(fileUrl, formData, {
    }).then((response) => {
        return response;
    }).then(async(data) => {
        fileId = data.data.resume_id
    })
        .catch((error) => {
            return(error)
        });
    return fileId
}
export default uploadFile
import React, { useState } from "react";
import { 
  Backdrop, Button, CircularProgress, DialogActions, DialogContent, DialogTitle, makeStyles,
} from "@material-ui/core";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  button: {
    marginTop: "12px"
  },
  input: {
    display: "none",
  },
  selectedFile: {
    display: "flex",
    color: "black",
    fontSize: "1rem",
    marginTop: "12px",
    "& > div:first-child": {
      color: "gray",
      marginRight: "6px"
    }
  }
}));
export default function ExistingEmployee(props) {
  const { authSessionToken, handleClose, handlePreviousStep} = props;
  const classes = useStyles();
  const fileUrl = `${process.env.REACT_APP_URL}/upload`;
  const personUrl = `${process.env.REACT_APP_URL}/person`;
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [selectedFile, setSelectedFile] = useState({
    fileContent: "",
    fileName: "",
  });

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  }

  const handleFileUpload = ({ target }) => {
    const reader = new FileReader();
    const file = target.files[0];

    reader.onload = function () {
      setSelectedFile({
        fileContent: reader.result.split(",")[1],
        fileName: file.name,
      });
      setDisableSubmit(false);
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setOpenBackdrop(true)
    uploadFile();
  };

  async function assignFileToPerson(resumeId) {
    await fetch(personUrl, {
      method: "PUT", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
        Authorization: authSessionToken,
      },
      body: JSON.stringify({person: {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        source: "",
        occupation: "",
        resumeId,
        skillsets: null,
        personType: "Employee"
      }
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        handleClose({ status: "success", msg: `File was uploaded successfully!`})
      })
      .catch((error) => {
        handleClose({ status: "error", msg: `The file upload has failed. Error: ${error}`})
      });
  }

  async function uploadFile() {
    await fetch(fileUrl, {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
        Authorization: authSessionToken,
      },
      body: JSON.stringify(selectedFile),
    })
      .then((response) => {
        return response.json();
      })
      .then(async(data) => {
        await assignFileToPerson(data.resume_id);
      })
      .catch((error) => {
        handleClose({ status: "error", msg: `Uploading the file has failed. Error: ${error}`});
      });
  }

  return (
    <form onSubmit={handleSubmit}>
      <DialogTitle id="form-dialog-title">Upload Resume</DialogTitle>
      <DialogContent dividers>
        <input
          accept="txt/*"
          className={classes.input}
          id="contained-button-file"
          type="file"
          onChange={handleFileUpload}
        />
        <label htmlFor="contained-button-file">
          <Button
            variant="contained"
            component="span"
            className={classes.button}
            startIcon={<CloudUploadIcon />}
          >
            Upload Resume
          </Button>
        </label>
        <div className={classes.selectedFile}>
          <div>Upload File:</div>
          <div>{selectedFile.fileName}</div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handlePreviousStep} color="primary">
          Previous
        </Button>
        <Button type="submit" disabled={disableSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
      <Backdrop className={classes.backdrop} open={openBackdrop} onClick={handleCloseBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </form>
  );
}

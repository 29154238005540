import React, {useEffect, useState} from "react";
import { 
    Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, TextField, Typography
  } from "@material-ui/core";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import axios from "axios";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff'
    },
    button: {
        marginTop: "12px"
    },
    input: {
        display: "none",
    },
    selectedFile: {
        display: "flex",
        color: "black",
        fontSize: "1rem",
        marginTop: "12px",
        "& > div:first-child": {
        color: "gray",
        marginRight: "6px"
        }
    }
}));

function useFormFields(initialValues) {
    const [formFields, setFormFields] = React.useState(initialValues);
    const createChangeHandler = (key) => (e) => {
        const {value} = e.target;
        const newValue = { value, touched: true}
        setFormFields((prev) => ({...prev, [key]: newValue }));
    };
    return { formFields, createChangeHandler };
}

export default function AddNewRequisition({ authSessionToken, handleClose, show}) {
    const classes = useStyles();
    const fileUrl = `${process.env.REACT_APP_URL}/uploadFile`;
    const reqUrl = `${process.env.REACT_APP_URL}/jobReq`;
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [fileUploaded, setFileUploaded] = useState(false);
    const { formFields, createChangeHandler } = useFormFields({
      position: { value : "", touched: false },
      lcat: { value: "", touched: false },
      location: { value: "", touched: false },
      project: { value: "", touched: false },
      client: { value: "", touched: false },
      level: { value: "", touched: false },
      clearance: { value: "", touched: false },
      description: { value: "", touched: false },
      mandatory: { value: "", touched: false },
      optional: { value: "", touched: false },
    });
    const [selectedFile, setSelectedFile] = useState({
      fileContent: "",
      fileName: "",
    });

    const getRowCount = (stringLength) => {
        if(stringLength > 400){
            return 5;
        }
        if(stringLength > 300 && stringLength <= 400){
            return 4;
        }
        if(stringLength > 200 && stringLength <= 300){
            return 3;
        }
        if(stringLength > 100 && stringLength <= 200){
            return 2
        }
        return 1;
      }

    const reset = () => {
        setFileUploaded(false);
        formFields.position = { value : "", touched: false };
        formFields.lcat= { value: "", touched: false };
        formFields.location = { value: "", touched: false };
        formFields.project = { value: "", touched: false };
        formFields.client = { value: "", touched: false };
        formFields.level = { value: "", touched: false };
        formFields.clearance = { value: "", touched: false };
        formFields.description = { value: "", touched: false };
        formFields.mandatory = { value: "", touched: false };
        formFields.optional = { value: "", touched: false };
        setSelectedFile({fileContent: "",fileName: "",})
    }

    const handleCloseBackdrop = () => {
        setOpenBackdrop(false);
    }
    
    const handleFileUpload = ({ target }) => {
        const file = target.files[0];
        setSelectedFile(file)
        setFileUploaded(true)
    };

    async function updateRequisition(attachment) {
      const body = {
        lcat: formFields.lcat.value,
        location: formFields.location.value,
        project: formFields.project.value,
        client: formFields.client.value,
        level: formFields.level.value,
        clearance: formFields.clearance.value,
        description: formFields.description.value,
        mandatory: formFields.mandatory.value,
        optional: formFields.optional.value,
          fileId: attachment.resume_id,
          filename: attachment.fileName,
        // attachment: { fileName: attachment.fileName, fileId: attachment.resume_id}
      }
      if(formFields.position.value){
        body.position = formFields.position.value
      }
      await fetch(reqUrl, {
        method: "POST", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
          Authorization: authSessionToken,
        },
        body: JSON.stringify({...body}),
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          handleClose({ status: "success", msg: `Requisition was added successfully!`})
          handleCloseBackdrop();
          reset()
        })
        .catch((error) => {
          handleClose({ status: "error", msg: `Assigning the file to the requisition has failed. Error: ${error}`});
          handleCloseBackdrop();
        });
    }
    
    async function uploadFileAndRequisition() {
        const data = new FormData()
        data.set('file', selectedFile)
        data.set('folder', 'resumes')
        await axios.post(fileUrl, data, {
        }).then((response) => {
            return response;
        }).then(async(data) => {
            await updateRequisition(data.data);
        })
            .catch((error) => {
                handleClose({ status: "error", msg: `Uploading the file has failed. Error: ${error}`});
                handleCloseBackdrop();
            });
    }

    const handleSave = async (event) => {
        event.preventDefault();
        setOpenBackdrop(true)
        await uploadFileAndRequisition();
    };

    return (
      <Dialog open={show} onClose={handleClose} maxWidth="sm" disableEscapeKeyDown disableBackdropClick fullWidth aria-labelledby="form-dialog-title">
        <form onSubmit={handleSave}>
          <DialogTitle id="form-dialog-title">Add New Requisition</DialogTitle>
          <DialogContent dividers>
            <Typography variant="subtitle1" gutterBottom>Enter New Requisition Information:</Typography>
            <TextField
              autoFocus
              margin="dense"
              label="Position"
              id="position"
              value={formFields.position.value}
              onChange={createChangeHandler("position")}
              type="number"
              fullWidth
              helperText="Position must be an integer."
            />
            <TextField
              margin="dense"
              label="Category"
              id="category"
              value={formFields.lcat.value}
              onChange={createChangeHandler("lcat")}
              onBlur={createChangeHandler("lcat")}
              fullWidth
              error={formFields.lcat.touched && formFields.lcat.value.trim() === ""}
              helperText={formFields.lcat.touched && formFields.lcat.value.trim() === "" ? "Required field." : ""}
              required
            />
            <TextField
              margin="dense"
              label="Location"
              id="location"
              value={formFields.location.value}
              onChange={createChangeHandler("location")}
              fullWidth
            />
            <TextField
              margin="dense"
              label="Project"
              id="project"
              value={formFields.project.value}
              onChange={createChangeHandler("project")}
              fullWidth
            />
            <TextField
              margin="dense"
              label="Client"
              id="client"
              value={formFields.client.value}
              onChange={createChangeHandler("client")}
              fullWidth
            />
            <TextField
              margin="dense"
              label="Level"
              id="level"
              value={formFields.level.value}
              onChange={createChangeHandler("level")}
              fullWidth
            />
            <TextField
              margin="dense"
              label="Clearance"
              id="clearance"
              value={formFields.clearance.value}
              onChange={createChangeHandler("clearance")}
              fullWidth
            />
            <TextField
              margin="dense"
              label="Description"
              id="description"
              value={formFields.description.value}
              onChange={createChangeHandler("description")}
              fullWidth
              multiline
              rows={getRowCount(formFields.description.value?.length)}
            />
            <TextField
              margin="dense"
              label="Mandatory Requirements"
              id="mandatory"
              value={formFields.mandatory.value}
              onChange={createChangeHandler("mandatory")}
              fullWidth
              multiline
              rows={getRowCount(formFields.mandatory.value?.length)}
            />
            <TextField
              margin="dense"
              label="Optional Requirements"
              id="optional"
              value={formFields.optional.value}
              onChange={createChangeHandler("optional")}
              fullWidth
              multiline
              rows={getRowCount(formFields.optional.value?.length)}
            />
            <input
              accept="txt/*"
              className={classes.input}
              id="contained-button-file"
              type="file"
              onChange={handleFileUpload}
            />
            <label htmlFor="contained-button-file">
              <Button
                variant="contained"
                component="span"
                className={classes.button}
                startIcon={<CloudUploadIcon />}
              >
                Upload Job Requisition
              </Button>
            </label>
            <div className={classes.selectedFile}>
              <div>Upload File:</div>
              <div>{selectedFile.name}</div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
            {/*<Button type="submit" disabled={formFields.lcat.value.trim() === "" || !fileUploaded} color="primary">*/}
              <Button type="submit" disabled={formFields.lcat.value.trim() === "" } color="primary">
              Submit
            </Button>
          </DialogActions>
          <Backdrop className={classes.backdrop} open={openBackdrop} onClick={handleCloseBackdrop}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </form>
      </Dialog>
    );
}

import React, {useEffect, useState} from 'react'

import 'date-fns'
import * as _ from 'lodash'
import Grid from '@material-ui/core/Grid'
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

import {useAuthContext} from "../../contexts/AuthContext";

import EmployeeAccepted from '../../assets/employAccept.svg';

import RequisitionCard from './RequisitionCard'

export default function Home() {

    const {auth} = useAuthContext();
    const url = `${process.env.REACT_APP_URL}/jobReq`;
    const lastWeek = new Date();
    lastWeek.setDate(lastWeek.getDate() - 7)
    const [requisitions, setRequisitions] = useState();
    const [dateFrom, setDateFrom] = useState(lastWeek)

    async function fetchData() {
        const options = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: auth.sessionToken.token,
            },
        };
        await fetch(url, options)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                const updatedRequisitions = data.map(req => {
                    const date = new Date(req.created_date)
                    if (req.last_updated === null) {
                        return {...req, created_date: date, last_updated: date}
                    }
                    const lastUpdated = new Date(req.last_updated)
                    return {...req, created_date: date, last_updated: lastUpdated}
                })
                setRequisitions(updatedRequisitions);
            })
            .catch((err) => {
                console.log('err: ', err);
            });
    }

    useEffect(() => {
        if (!auth.loading) {
            fetchData();
        }
    }, [auth.loading]);

    const getMostRecentReq = () => {
        const lastWeek = new Date();
        lastWeek.setDate(lastWeek.getDate() - 7)
        const returnValue = _.filter(requisitions, (req) => {return req.last_updated > lastWeek})
        return returnValue
        // const returnValue = requisitions.filter(req =>
        //     req.last_updated.getDate() > dateFrom.getDate() && req.last_updated.getFullYear() === dateFrom.getFullYear()
        // )
        // return returnValue
    }
    
    const handleDateChange = (date) => {
        setDateFrom(date)
    }
    
    return (
      <Grid container direction = "column">
        <Grid item>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    // disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Show tickets modified from"
                    value={dateFrom.toDateString()}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </MuiPickersUtilsProvider>
        </Grid>
        <Grid item>
          <Grid container>
            {
                        requisitions ? getMostRecentReq().map((req) => (
                          <>
                            <RequisitionCard
                              active
                              image={EmployeeAccepted}
                              location={req.location}
                              numberOfInstances='1'
                              title={req.lcat}
                              id={req.id}
                              lastModified={req.last_updated}
                            />
                          </>
                            ))
                            :
                        <></>
                    }
          </Grid>

        </Grid>
      </Grid>
    )

}

import React, { useState, useRef } from "react";
// import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const options = [
  { value: 'employee', label: 'Existing Employee'},
  { value: 'candidate', label: 'New Candidate'}
];

export default function EmployeeType(props) {
  const { handleClose, handleNextStep } = props;
  const radioGroupRef = useRef(null);
  const [value, setValue] = useState('');
  const [disableNext, setDisableNext] = useState(true);

  const handleChange = (event) => {
    if(event.target.value){
        setDisableNext(false);
    }
    setValue(event.target.value);
  };

  return (
    <div>
      <DialogTitle id="confirmation-dialog-title">Employee Type</DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          ref={radioGroupRef}
          aria-label="employee type"
          name="employee type"
          value={value}
          onChange={handleChange}
        >
          {options.map((option) => (
            <FormControlLabel value={option.value} key={option.value} control={<Radio />} label={option.label} />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        <Button disabled={disableNext} onClick={() => handleNextStep(value)} color="primary">
          Next
        </Button>
      </DialogActions>
    </div>
  );
}
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";
import {
  AppBar, Container, CssBaseline, Drawer, Divider, IconButton, List, ListItem, ListItemIcon, ListItemText, makeStyles, Snackbar, Toolbar,
  useTheme
} from "@material-ui/core";
import {
  BusinessCenter as BusinessCenterIcon, ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon, ExitToApp as ExitToAppIcon,
  Home as HomeIcon, Menu as MenuIcon, People as PeopleIcon, Publish as PublishIcon
} from "@material-ui/icons"
import MuiAlert from '@material-ui/lab/Alert';
import {
  Candidates as CandidatesPage, Home as HomePage, Person as PersonPage, Requisition as RequisitionPage, Requisitions as RequisitionsPage, Page404
} from "./pages"
import { useAuthContext } from "./contexts/AuthContext";
import PolihireLogo from "./assets/PolihireLogo.png";
import FlexwindLogo from "./assets/FlexwindLogo.png"
import { UploadResume as UploadResumeDialog } from "./components"

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: 0,
    }
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    backgroundColor: "#18202c",
    color: "#ffff",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: "#18202c",
    color: "#ffff",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  drawerToolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  toolbar: {
    justifyContent: "space-between"
  },
  toolbarLeft: {
    display: "flex"
  },
  appBarIcon: {
    color: '#18202c'
  },
  listItemIcon: {
    color: '#ffff'
  },
  divider: {
    backgroundColor: '#404854'
  },
  logoContainer: {
    width: 200,
    margin: "auto"
  },
  logoImg: {
    width: 190,
    height: 34,
    marginLeft: "auto",
    marginRight: "auto"
  },
  viewContainer: {
    paddingTop: "96px",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingBottom: "96px"
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function App() {
  const classes = useStyles();
  const theme = useTheme();
  const { auth, dispatch } = useAuthContext();
  const [open, setOpen] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const listItems = [
    { title: "Dashboard", icon: <HomeIcon fontSize="default" />, link: "/" },
    { title: "Positions", icon: <BusinessCenterIcon fontSize="default" />, link: "/requisitions" },
    { title: "Candidates", icon: <PeopleIcon fontSize="default" />, link: "/candidates" }
  ];

  async function signOut() {
    try {
      await Auth.signOut();
      window.location.reload();
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDialogToggle = (queryResponse) => {
    if (queryResponse.status) {
      setOpenSnackbar(true);
      setSnackbarSeverity(queryResponse.status)
      setSnackbarMessage(queryResponse.msg)
    }
    setShowDialog(!showDialog);
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false)
  }

  async function authenticateUser() {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    let currentSession = cognitoUser.signInUserSession;
    let sessionToken = {
      token: currentSession.getIdToken().getJwtToken(),
      expires_at: currentSession.getIdToken().payload.exp * 1000,
    }
    dispatch({ type: "SET_TOKEN", payload: { sessionToken, loading: false } })
    setInterval(() => {
      if (sessionToken.expires_at <= Date.now()) {
        signOut();
      }
    }, 5000)
    setInterval(() => {
      cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
        currentSession = session;
        sessionToken = {
          token: currentSession.getIdToken().getJwtToken(),
          expires_at: currentSession.getIdToken().payload.exp * 1000
        }
        dispatch({ type: "SET_TOKEN", payload: { sessionToken, loading: false } })
      });
    }, 600000)
  }

  useEffect(() => {
    if (auth.loading) {
      authenticateUser();
    }
  }, [auth.loading]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Router>
        <AppBar
          position="fixed"
          style={{ backgroundColor: "#fff" }}
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar className={classes.toolbar}>
            <div className={classes.toolbarLeft}>
              <IconButton
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}
              >
                <MenuIcon />
              </IconButton>
              <div className={classes.logoContainer}>
                {/*{process.env.REACT_APP_STAGE === "polihire" ? (*/}
                {true ? (
                  <img
                    alt="Polihire"
                    src={PolihireLogo}
                    className={classes.logoImg}
                  />
              ) : (
                <img
                  alt="Flexwind"
                  src={FlexwindLogo}
                  className={classes.logoImg}
                />
                )}
              </div>
            </div>
            <IconButton onClick={signOut} className={classes.appBarIcon} aria-label="sign out">
              <ExitToAppIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, { [classes.drawerOpen]: open, [classes.drawerClose]: !open })}
          classes={{ paper: clsx({ [classes.drawerOpen]: open, [classes.drawerClose]: !open }) }}
        >
          <div className={classes.drawerToolbar}>
            <IconButton color="inherit" onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </div>
          <Divider classes={{ root: classes.divider }} />
          <List>
            {listItems.map((listItem) => (
              <ListItem button key={listItem.title} component={Link} to={listItem.link}>
                <ListItemIcon className={classes.listItemIcon}>
                  {listItem.icon}
                </ListItemIcon>
                <ListItemText primary={listItem.title} />
              </ListItem>
            ))}
            {/*<Divider classes={{ root: classes.divider }} />*/}
            {/*<ListItem button onClick={handleDialogToggle}>*/}
            {/*  <ListItemIcon className={classes.listItemIcon}>*/}
            {/*    <PublishIcon fontSize="default" />*/}
            {/*  </ListItemIcon>*/}
            {/*  <ListItemText primary="Upload Resume" />*/}
            {/*</ListItem>*/}
          </List>
          <Divider classes={{ root: classes.divider }} />
        </Drawer>
        <Container maxWidth="xl" className={classes.viewContainer}>
          <Switch>
            <Route exact path="/"><HomePage /></Route>
            <Route path="/requisitions"><RequisitionsPage /></Route>
            <Route path="/requisition/:reqId"><RequisitionPage /></Route>
            <Route path="/candidates"><CandidatesPage /></Route>
            <Route path="/person/:personId">
              <PersonPage />
            </Route>
            <Route><Page404 /></Route>
          </Switch>
        </Container>
      </Router>
      <UploadResumeDialog authSessionToken={auth.sessionToken.token} handleClose={handleDialogToggle} show={showDialog} />
      <Snackbar open={openSnackbar} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  )
};

export default withAuthenticator(App);